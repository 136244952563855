<template>
  <div class="grey lighten-4 rounded my-5">
    <v-row class="pb-0">
      <v-col
        align-self="center"
        cols="1"
        class="pl-7"
      >
        <v-checkbox
          :input-value="allSelected"
          :label="allSelectedLabel"
          hide-details
          color="teal"
          @change="selectAll"
        />
      </v-col>
      <v-col
        align-self="center"
        class="grey lighten-3"
        offset="1"
        cols="2"
      >
        <v-btn
          :disabled="!selectedItems.length"
          color="teal lighten-2"
          small
          class="elevation-0 mr-2"
          @click="deleteSelectedDocuments"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          :disabled="!selectedItems.length"
          color="teal lighten-2"
          small
          class="elevation-0 mr-2"
        >
          <v-icon>mdi-sort</v-icon>
        </v-btn>
        <v-btn
          :disabled="!selectedItems.length"
          color="teal lighten-2"
          small
          class="elevation-0 mr-2"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-virtual-scroll
      height="300px"
      class="my-5"
      :items="items"
      bench="10"
      item-height="50"
    >
      <template #default="{item}">
        <document-details-item
          :item="item"
          :currently-edited-id="currentEditedId"
          :selected="selectedItems"
          @edit="(id) => $emit('edit', id)"
          @select="onSelect"
        />
      </template>
    </v-virtual-scroll>
    <v-row class="py-0">
      <v-col class="pl-7">
        <v-btn
          :disabled="loading"
          min-width="40px"
          width="40px"
          class="elevation-0"
          color="teal lighten-2"
          @click="createDocument"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import DocumentDetailsItem from "@/components/dt/documents/documents-details/document-details-item.vue";

export default {
  components: {DocumentDetailsItem},
  props: {
    currentEditedId: {
      required: true,
      validator: (v) => typeof v === 'number' || v === null
    },
  },
  data() {
    return {
      loading:false,
      selectedItems: [],
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      selected: 'dt/getSelected'
    }),
    allSelected() {
      return this.selectedItems.length === this.items.length
    },
    allSelectedLabel(){
      return `${this.selectedItems.length}/${this.items.length}`
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    selectAll() {
      this.selectedItems = this.selectedItems.length === this.items.length ? [] : this.items.map(document => document.id)
    },
    onSelect(id) {
      this.selectedItems.includes(id)
        ? this.selectedItems = this.selectedItems.filter(i => i !== id)
        : this.selectedItems.push(id)
    },
    setFields() {
      this.items = this.selected?.presented_documents || [];
    },
    createDocument(){
      this.loading = true
      this.$store.dispatch("dt/addDocument").finally(() => this.loading = false)
    },
    unsetSelected(){
      this.selectedItems = []
    },
    deleteSelectedDocuments(){
      this.$store.dispatch("dt/deleteDocumentArr", this.selectedItems)
        .catch(() => this.$error());
      this.unsetSelected()
    }
  }
}
</script>
